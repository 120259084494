<template>
  <div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c6="" class="pilicy-title">
            <div _ngcontent-c6="" class="line"></div>
            <div _ngcontent-c6="" class="innerbox">
              <div _ngcontent-c6="" class="title1">政策解读</div>
              <div _ngcontent-c6="" class="title2">法律法规</div>
            </div>
          </div>
          <!--  -->
          <div _ngcontent-c5="" class="train-list clearfix">
            <ul _ngcontent-c5="">
              <li _ngcontent-c5="" v-for="(item, index) in zcjdList" :key="index">
                <a _ngcontent-c5="" >
                  <img _ngcontent-c5="" alt="" :src="`/_fid/` + item.image">
                  <p _ngcontent-c5="" class="train-name">{{item.title}}</p>
                  <p _ngcontent-c5="" class="train-detail cursor" @click="onClickDtl(item.id)">查看详情</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getArticleList } from "@/api/common.js"
export default {
  setup(props) {
    const router = useRouter()
    const state = reactive({
      zcjdList: []
    })
    const getArticle = (cmsTypeId) => {
      getArticleList({ current: 1, size: 100, status: 2, cmsTypeId: 3 }).then(res => {
        if (res.code === 20000) {
          state.zcjdList = res.result.records
        }
      })
    }
    getArticle()
    const onClickDtl = (id) => {
      const newpage = router.resolve({ path: '/new/detail', query: { cmsTypeId: 3, id} })
      window.open(newpage.href, '_blank')
    }
    return {
      ...toRefs(state),
      onClickDtl
    }
  }
}
</script>

<style lang="less" scoped>
 .pilicy-title[_ngcontent-c6]{
  margin-top: 50px;
  width: 100%;
  height: 80px;
  position: relative;
}
.pilicy-title[_ngcontent-c6]   .line[_ngcontent-c6]{
  width: 100%;
  height: 2px;
  background: #4F17A8;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
}
.pilicy-title[_ngcontent-c6]   .innerbox[_ngcontent-c6]{
  width: 180px;
  height: 60px;
  background: #FFF;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
.pilicy-title[_ngcontent-c6]   .innerbox[_ngcontent-c6]   .title1[_ngcontent-c6]{
  height: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #4F17A8;
  line-height: 30px;
}
.pilicy-title[_ngcontent-c6]   .innerbox[_ngcontent-c6]   .title2[_ngcontent-c6]{
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}

.train-list[_ngcontent-c5]{width:100%;margin: 40px 0;}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]{width:290px;height:260px;float: left;padding: 4px;margin-right: 10px; box-sizing: border-box; margin-bottom: 20px;}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]:nth-last-child(1){margin-right: 0;}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]   img[_ngcontent-c5]{width:284px;height:159px;}

.train-name[_ngcontent-c5]{
  font-size: 14px;
  color: #666;
  width:100%;
  overflow: hidden;
  height: 58px;
  line-height: 24px;
  display: block;
  margin-top: 5px;
  margin-bottom: 2px;
  padding: 5px 0px 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.train-price[_ngcontent-c5]{font-size: 14px;float: left;color: red;}
.train-detail[_ngcontent-c5]{font-size: 14px;float: right;padding-right: 8px;color: #4F17A8}
.train-list[_ngcontent-c5]   li[_ngcontent-c5]:hover{box-shadow: 0 0  25px #aaa;}
  
  
  </style>
