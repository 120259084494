import http from '@/utils/request.js'

// 首页轮播

export const getSwiperList = (params) => {
  return http.post('/n/system/cmsBanner/getList', params, { domainName: 'cpi' })
}

// 新闻文章

export const getArticleList = (params) => {
  return http.post('/n/system/cmsArticle/page', params, { domainName: 'cpi' })
}

export const getArticleinfo = (params) => {
  return http.post('/n/system/cmsArticle/get', params, { domainName: 'cpi' })
}

export const downLoadFile = (params) => {
  // return http.post('/n/system/noAccessDownloadFile', params, { domainName: 'epi' }, { responseType: 'blob' })
  return http({
    method: 'post',
    url: '/n/system/noAccessDownloadFile',
    data: params,
    responseType: 'blob',
    domainName: 'epi'
  })
}

